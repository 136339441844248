const radii = {
    none: "0",
    sm: "5px",
    base: "8px",
    md: "12px",
    lg: "20px",
    xl: "30px",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
}

export default radii