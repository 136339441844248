import { Icon } from "@chakra-ui/react";

const AvaxLogo = ({ fill = "currentColor", ...props }) => (
  <Icon viewBox="0 0 200 200" {...props}>
    <path
      fill={fill}
      d="M11.7,6c0,3.1-2.5,5.7-5.6,5.8c-3.2,0.1-5.8-2.5-5.9-5.6C0.2,2.9,2.7,0.3,5.8,0.3C9.1,0.2,11.7,2.8,11.7,6z M3.9,8.3
	C3.9,8.3,3.9,8.3,3.9,8.3c0.3,0,0.6,0,0.8,0c0.3,0,0.5-0.1,0.7-0.4C5.6,7.5,5.9,7,6.1,6.5C6.5,6,6.8,5.4,7.1,4.9
	c0.2-0.3,0.2-0.6,0-0.9C7,3.8,6.9,3.6,6.8,3.4C6.6,3.1,6.4,2.8,6.3,2.6c-0.1-0.2-0.4-0.2-0.5,0C5.7,2.6,5.7,2.7,5.6,2.8
	C4.9,4,4.3,5.2,3.6,6.3C3.3,6.8,3,7.3,2.8,7.8C2.6,8.1,2.7,8.3,3,8.3C3.3,8.3,3.6,8.3,3.9,8.3z M8,8.3C8,8.3,8,8.3,8,8.3
	c0.3,0,0.7,0,1,0c0.3,0,0.4-0.2,0.3-0.5C9.3,7.7,9.2,7.7,9.1,7.6C8.8,7.1,8.5,6.6,8.3,6C8.1,5.8,7.9,5.8,7.7,6
	C7.4,6.6,7,7.2,6.7,7.8C6.5,8.1,6.7,8.3,7,8.3C7.3,8.3,7.7,8.3,8,8.3z"
    />
  </Icon>
);

export default AvaxLogo;
