import React from 'react';
import NavBarMain from "./NavBarMain";


export default function Header(props) {

    return (
        <>
            <NavBarMain {...props} />
        </>
    )
}
