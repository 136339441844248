const colors = {
  gray: {
    50: "#F9F9F9",
    10: "rgba(149,149,149,0.1)",
    5: "rgba(149,149,149,0.05)",
  },
  primary: {
    50: "#E5FFF9",
    100: "#B8FFEE",
    200: "#8AFFE3",
    300: "#5CFFD8",
    400: "#2EFFCD",
    // 500: "#00FFC2",
    500: "#00CC9B",
    600: "#00CC9B",
    700: "#009974",
    800: "#00664E",
    900: "#003327",
  },
  secondary: {
    50: "#FFF2E5",
    100: "#FFDBB8",
    200: "#FFC48A",
    300: "#FFAC5C",
    400: "#FF952E",
    500: "#FF7E00",
    600: "#CC6500",
    700: "#994B00",
    800: "#663200",
    900: "#331900",
  },
  third: {
    50: "#E5FFF9",
    100: "#B8FFEE",
    200: "#8AFFE3",
    300: "#5CFFD8",
    400: "#2EFFCD",
    // 500: "#00FFC2",
    500: "#00CC9B",
    600: "#00CC9B",
    700: "#009974",
    800: "#00664E",
    900: "#003327",
  },
  cream: {
    500: "#F8DEA6",
  },
};

export default colors;
