import React from "react";
import { Center } from "@chakra-ui/react";

export default function YouWhoLogo({ ...props }) {
    return (
        <Center w={props.wdth}>
            <svg
                id="e3672028-0f04-49cc-ac49-5714ba4424a6"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 524.45 680.96"
                {...props}
            >
                <title>youwho-teal</title>
                <rect width="524.45" height="680.96" rx="262.23" style={{ "fill": "#00c994" }} />
                <path
                    d="M189.06,101.53c.66-.09,1.35-.22,2.06-.39,2-.48,4-1.08,5.89-1.65l1.35-.41a240.56,240.56,0,0,1,38.46-8.18,211.55,211.55,0,0,1,31-1.59c2.48.06,5,.1,7.48.14,11.38.19,23.15.38,35.19,2.39,10.45,1.75,39.78,10.85,43.71,12.07l5.44,2.34c6,2.58,11.62,5,17.45,7a8.57,8.57,0,0,0,3.81.29,12.64,12.64,0,0,0,6.66-2.89c2.58-2.35,2.89-7.07,2.47-9.66-.84-5.23-13.22-11.48-19.16-12.49-2.16-.84-18.57-7.21-24.53-9.12-7.05-2.26-20.89-6.31-28-7.57a206.24,206.24,0,0,0-22.54-2.27c-17.83-.52-38.54-.91-58.66,1.14-1.12.11-2.23.24-3.35.38-11.64,1.45-23.25,4.29-34.48,7-4.49,1.09-9.13,2.22-13.69,3.25a10.6,10.6,0,0,0-7.07,4.71,10.34,10.34,0,0,0-.78,8.26C179.24,99.61,183.36,102.24,189.06,101.53Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M98.31,147.74c.76.45,1.41,1,2,1.25a4.48,4.48,0,0,0,1.26.24,5.89,5.89,0,0,0,1.57,0,12.12,12.12,0,0,0,2.44-1.16,15.14,15.14,0,0,0,3.5-2,283.84,283.84,0,0,1,52.19-33.17,11,11,0,0,0,5.82-6.27,9.61,9.61,0,0,0-.88-7.5c-2.24-4.23-5.82-6.27-10.09-5.74a13.87,13.87,0,0,0-4.34,1.37c-1.39.69-2.8,1.35-4.21,2a87,87,0,0,0-11,5.83c-11.18,7.35-25.83,17.11-40,27.49-3.21,2.35-4.73,8.34-3.91,11.92C93.27,144.8,95.94,146.36,98.31,147.74Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M121.42,507.94l.47-.92c24.16-46.83,33.27-97.15,27.07-149.55a122,122,0,0,1,2.45-44.41c8-31.59,25.87-56.56,53.14-74.2a16.4,16.4,0,0,0,4.8-5.63c.31-.52.43-.77.6-1.06a4.33,4.33,0,0,0,.37-1.25,14.32,14.32,0,0,0,.07-2.07,9.7,9.7,0,0,0-4.11-7.76,9.18,9.18,0,0,0-6.89-1.89,9.59,9.59,0,0,0-1.49.3,23,23,0,0,0-6.75,3.44c-47.26,32.94-68.36,78.36-62.73,135,3.83,38.52.48,72.44-10.26,103.73-3.55,10.34-8.22,20.56-12.73,30.45-1.64,3.57-3.27,7.15-4.86,10.74-3.61,8.19,0,12.4,3.63,14.49a10.46,10.46,0,0,0,6.58,1.54c3.27-.41,6-2.57,8.23-6.43C119.87,511,120.66,509.43,121.42,507.94Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M101.66,472.67c.67-2,1.34-4,2-6,2.8-8.3,5.69-16.88,8.06-25.49,9.07-33,6.12-66.76,3.27-99.4,0,0-.22-3.75-.43-5a28.82,28.82,0,0,0-2.28-6.92,9.5,9.5,0,0,0-6.49-4.79,9.74,9.74,0,0,0-3.42-.19c-4.59.57-7.73,4.76-7.82,10.43a170.66,170.66,0,0,0,.9,23.18c4.63,37.42.17,73.37-13.25,106.86-2.78,6.93-.94,12.31,5,14.75A11.48,11.48,0,0,0,93,481C97,480.52,100,477.56,101.66,472.67Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M74.45,373.67c2.9-.37,5.69-3.08,8.54-8.29,1-1.83.65-3.66.54-5.44-.06-1.09-.1-.78-.16-2.35-.17-5.25.22-10.55.39-15.71.42-12.35.84-25.13,2.56-37.44,3.21-23,13.78-44,22.63-59.65,2-3.56,1.62-8.91.23-12.13-1.32-3.07-6.66-4.55-9.61-4.18a6.85,6.85,0,0,0-1.06.23c-4.05,1.24-7,5.51-8,7.28-21.41,35.89-31,75.57-28.55,118l0,.18c.18,1.47.27,2.87.35,4.23.18,3.08.35,6,1.71,8.5,1.67,3.06,6.31,7.26,10.35,6.82Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M57.7,249.63A10.69,10.69,0,0,0,64,250.94h0c2.24-.28,5.44-1.57,8-6.12.4-.73.77-1.48,1.13-2.22.24-.49.47-1,.72-1.44,22.49-43.25,55.93-76,99.38-97.37a212,212,0,0,1,67.48-19.93,251.24,251.24,0,0,1,27-1.87,33.73,33.73,0,0,0,3.66-.24c8.91-1.11,10.79-6.21,10.79-10.29a9.39,9.39,0,0,0-2.81-7c-2.52-2.44-6.5-3.57-11.81-3.37-6.41.24-12.9.59-19.18.93l-8.39.44-2.78.16-2.58.33-4.95,1c-2.78.35-4.42,1.21-7.19,1.57a25.18,25.18,0,0,0-2.67.54l-.86.21C163.55,118.7,118.44,146.07,84.8,187.58c-8.79,10.85-16.19,23-23.34,34.77-2.52,4.15-5.13,8.44-7.75,12.59a10.42,10.42,0,0,0-1.52,8.3A10.3,10.3,0,0,0,57.7,249.63Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M394.08,203.33c1.13,1,2.2,2.14,3.34,3.31a42.36,42.36,0,0,0,7.34,6.5,12.8,12.8,0,0,0,8.08,1.53,10.32,10.32,0,0,0,2.93-.77c2.31-1,3.51-3.27,4.68-5.47a16.55,16.55,0,0,0,1-2,6.76,6.76,0,0,0,0-1.74,8.86,8.86,0,0,0-.44-2.18,7,7,0,0,0-1-1.81,25.18,25.18,0,0,0-3.4-4.5l-1.42-1.39c-4.78-4.68-9.72-9.52-15-13.87a207.42,207.42,0,0,0-268.68,5A251.66,251.66,0,0,0,113.3,204.5l-.29.32c-4.26,4.65-4.13,10.53.32,14.63a9.77,9.77,0,0,0,8,2.88,12.24,12.24,0,0,0,7.06-3.84c1.23-1.23,2.4-2.52,3.54-3.77.81-.89,1.62-1.78,2.45-2.65a185.88,185.88,0,0,1,259.71-8.74Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M284.3,475.61c-1.3,5.51-2.65,11.21-3.77,16.88-1.17,5.9,1.48,10.53,6.92,12.08A11.32,11.32,0,0,0,292,505c3.67-.46,6.63-3,8.32-7.11a22.61,22.61,0,0,0,1.05-3.62c.1-.42.19-.84.3-1.26a418.56,418.56,0,0,0,11.46-142.37c-.22-1.8-.41-3.61-.6-5.42-.42-4-.86-8.08-1.66-12.1-4.49-22.44-24.78-37.24-47.2-34.43l-.93.12c-22.42,3.3-38.37,23.15-37.12,46.19,1,17.58,1.54,35.5,2.11,52.83l.34,10.19c.17,5.09,1.46,8.77,3.85,10.94a8.73,8.73,0,0,0,6.56,2.17q.49,0,1-.09c3.56-.45,9.48-2.67,9.26-12.53q-.15-6.92-.27-13.81c-.3-15.75-.61-32-1.59-48-.92-15.06,6.58-25.81,19.11-27.38l.28,0A22,22,0,0,1,282.59,324a25.09,25.09,0,0,1,9,17.25c4.95,48.7,2.58,92.56-7.23,134.08Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M334.6,457.59h0a10.68,10.68,0,0,0,4.68-1.66c3.4-2.44,4.34-7.75,4.54-9.32a467,467,0,0,0,2.5-97.38l-.64-5.1q-.69-5.46-1.36-10.91a76.6,76.6,0,0,0-28.81-51.63,75.22,75.22,0,0,0-56-15.47A78.58,78.58,0,0,0,234,273.93c-30.19,15.14-47.24,51-39.65,83.37,1.43,6.11,5.54,9.15,11.58,8.54l.34,0c5.56-.7,8.55-4.65,8.2-10.85-.1-1.77-.25-3.53-.4-5.29-.2-2.43-.41-4.95-.47-7.39-.75-28,20.63-51.9,49.73-55.54.7-.09,1.4-.17,2.11-.23,27.67-2.52,54,18.43,57.43,45.76a470.26,470.26,0,0,1,3.64,58c0,11.23-.89,22.69-1.76,33.78-.56,7.13-1.14,14.5-1.47,21.77a13.93,13.93,0,0,0,4.86,10.52C330.22,457.93,333.35,457.74,334.6,457.59Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M256.51,167.17l-.84.1c-38.28,4.79-73.1,19.62-100.71,42.88a167.58,167.58,0,0,0-54.84,86.49c-1.28,4.95-1.09,8.74.58,11.57a9.54,9.54,0,0,0,6.16,4.35,10.55,10.55,0,0,0,4,.3c3.1-.39,7.25-2.5,9.39-10.37,11.16-41,35.21-72.53,71.47-93.76a151.66,151.66,0,0,1,57.95-20.11c20.12-2.52,41.18-.75,62.6,5.27a142.2,142.2,0,0,1,48.12,24.21c2.93,2.24,6.45,4.46,10.43,4h0a10.76,10.76,0,0,0,6.26-3.33,8.88,8.88,0,0,0,2.78-7.11c-.25-3.5-2.49-6.91-6.45-9.85C337.58,175.18,296.08,162.89,256.51,167.17Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M350.73,523.43a11.54,11.54,0,0,0,4.42.37h0c2.41-.31,6.75-1.87,8.64-9,.56-2.06,1.1-3.77,1.58-5.29a33.09,33.09,0,0,0,2-9c.15-2.83,2.18-11.5,4-19.15,2-8.63,3.92-16.78,4.07-20L377,444c.89-6.52,1.64-13.19,2.23-19.8a400.55,400.55,0,0,0-3.83-103.3c-8.21-48.79-52.36-86.75-102.69-88.29a23.38,23.38,0,0,0-3.55.14c-7.23.91-8.93,5.82-9.09,9.79a10,10,0,0,0,2.41,7.44c1.93,2.08,4.9,3.21,8.81,3.36,46.28,1.77,82.48,37.78,86.07,85.64,2.87,38.18,2.91,67.43.14,94.85-.42,4.13-.81,8.25-1.2,12.38-.7,7.29-1.41,14.83-2.26,22.22a31.88,31.88,0,0,1-.93,3.84c-.22.76-.41,1.45-.53,1.94l-1.92,5.81-.05.18c-1.49,5.77-2.86,11.68-4.2,17.4-.93,4-1.86,8-2.83,12C341.8,516.71,344.42,521.75,350.73,523.43Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M331,509.78l5.1-23.24c2.33-4.73,2.08-9.82-.67-13.06a11.23,11.23,0,0,0-4.81-3.18,9.84,9.84,0,0,0-4.23-.43c-4.26.54-7.57,4-9.09,9.6-.14.49-.24,1-.34,1.48l-.13.62A435.65,435.65,0,0,1,274.45,595c-2.23,4.11-2.89,7.73-2,10.75a9.5,9.5,0,0,0,5,5.68,10.08,10.08,0,0,0,5.93,1.22h.05c3.51-.44,6.67-3,9.37-7.75,3.91-6.79,18.86-44,38.1-94.93Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M407.37,465.73c.61-3.78,2.4-7.43,1.57-11.72a18.2,18.2,0,0,0-1-2.54,8.84,8.84,0,0,0-2-2.43,14,14,0,0,0-7.08-3.24,10.17,10.17,0,0,0-2.64,0c-4.35.54-7.2,3.94-8.44,10.1-1.17,5.82-2.3,11.66-3.43,17.5-3.1,16.06-6.31,32.68-10.53,48.69-3.37,12.77-8,25.51-12.41,37.83-2,5.47-4,11.13-5.92,16.73-1.52,4.45-.93,9.11,1.54,12.15a8,8,0,0,0,7.35,3,9.71,9.71,0,0,0,1.46-.3c4.29-1.24,8.19-5.76,9.68-9.38a497.66,497.66,0,0,0,28-93.72C404.91,481,406.11,473.6,407.37,465.73Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M402.86,229.82c-2.66-3.16-6-6.32-10.65-5.74a11.27,11.27,0,0,0-5,2.07,9.57,9.57,0,0,0-4.27,6.59c-.43,3.21.84,6.63,3.77,10.16,20.59,24.78,32.52,54.39,35.47,88,.66,7.51,1.69,15.09,2.6,21.78.39,2.86.44,5.56.77,8.15l.41,17.69c-.1,2.14-.21,4.18-.31,6.15-.23,4.15-.44,8.07-.48,12-.54,48.85-8.44,98.43-23.45,147.37-2.41,7.85-.38,12.85,6,14.86a11.93,11.93,0,0,0,5,.53h0c4.22-.52,7.37-3.72,8.88-9,.65-2.29,1.32-4.58,2-6.87,1.44-4.95,2.94-10.07,4.27-15.17,14.19-54.26,20.19-105.2,18.35-155.73l0-.8c0-.46-.2-11.3-.5-15-1.36-17.21-2.94-31-5-43.5C435.55,281.63,422.8,253.55,402.86,229.82Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M309.16,104.58a44.76,44.76,0,0,0-4.79-.66,8,8,0,0,0-2.9.31,6.94,6.94,0,0,0-2.27,1.45c-.51.59-1.37,1.71-2,2.43-1.57,1.9-2.17,2.55-2.76,5.22-1.17,5.29,2.06,9.54,8.42,11.09,3.55.87,7.17,1.67,10.67,2.45,7.65,1.7,15.57,3.47,23.05,5.91a216.91,216.91,0,0,1,97.82,65.12c2.22,2.57,5.73,5.92,10.51,5.33h0a11.47,11.47,0,0,0,5.28-2.27,9.14,9.14,0,0,0,3.88-6.44c.37-3.28-1-6.86-4.08-10.35-34.4-39.57-78-65.64-129.71-77.48C316.76,105.88,313.24,105.28,309.16,104.58Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M353.25,540.75a10.21,10.21,0,0,0-5.89-5.33,10.71,10.71,0,0,0-5.26-.76c-3.63.46-6.63,3-8.44,7.28-7.74,18.13-14.73,35-21.36,51.56a9.68,9.68,0,0,0,1.16,9.73c2.37,3.07,6.5,4.56,11.06,4l.54-.08L326,607l.63-.68c.23-.24.57-.52.94-.84a14.77,14.77,0,0,0,4.26-5.12c7.28-17,14.14-33.78,21.11-50.92A11.2,11.2,0,0,0,353.25,540.75Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M472.72,304.66l0-.17a211,211,0,0,0-21.88-62c-3.08-5.65-6.68-11.49-10.68-17.37-2.35-3.46-6-5.34-9.44-4.9a8.61,8.61,0,0,0-5.05,2.57c-2.34,2.36-2.67,6.11-3,9.43a12.84,12.84,0,0,0,.16,2.72,7.28,7.28,0,0,0,1.41,3.74,10,10,0,0,1,.61.8c11.92,18.62,19.86,36.77,24.27,55.49,1,4.27,1.81,8.71,2.59,13,.44,2.44.88,4.88,1.36,7.31,1.37,6.95,5.8,10.65,11.85,9.9l.33-.05c6-.9,9.26-5.69,8.45-12.51-.17-1.48-.36-3-.6-4.81Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M312.8,206.91A133.45,133.45,0,0,0,253,200.56a139.25,139.25,0,0,0-24.6,5.43c-6.77,2.15-9.38,6.38-7.75,12.6,1,3.86,3.79,8.28,11.3,7.34.58-.08,1.18-.18,1.79-.3s1.26-.32,1.89-.5l.86-.25a125,125,0,0,1,17-3.34c60.9-7.63,117.68,31.4,132.07,90.78,3.29,13.6,4.31,28.11,5.29,42.14.29,4.06.57,8.12.91,12.15.67,8,.74,16.27.82,24.25,0,3.65.06,7.29.15,10.94l-.32,6.16c-.08,1.09-.21,2.18-.34,3.28a32.79,32.79,0,0,0-.29,8.36,31.56,31.56,0,0,0,4.1,11.3c1.42,2.43,5.47,2.49,6.91,2.31a5.62,5.62,0,0,0,2.37-.79c1.43-.91,6.12-4.19,6.4-8.17.14-1.91.42-3.86.71-5.93a61.44,61.44,0,0,0,.85-9.89l.1-30v-7.11l0-1.07c-.05-.44-.1-1.2-.12-1.64-.17-4.21-.23-6.12-.47-10.23-.31-5.09-.51-10.15-1.18-15.11-.33-2.47-.67-5-.83-7.47C406.7,274.56,370.13,226.37,312.8,206.91Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M244.74,444.71a28.85,28.85,0,0,0-.31-4l-.6-1.53c-.4-.32-.58-.81-1.12-1.35-1.74-1.69-3.9-3.81-6.7-4a7.57,7.57,0,0,0-1.5,0c-2.93.37-5.64,2.33-8,4.06l-.53.38c-1.71,1.22-1.95,3.39-2.14,5.14,0,.36-.08.71-.13,1-6.7,40.15-21.21,79.23-43.13,116.16-1.21,2-2.52,4.09-3.78,6.07-1.12,1.76-2.24,3.53-3.32,5.31-3.06,5.05-2.09,10.31,2.52,13.7a10.5,10.5,0,0,0,7.58,2.26,10.39,10.39,0,0,0,6.82-4.16,73.39,73.39,0,0,0,4.73-6.91c24.91-40.64,41.42-83.76,49.06-128.16C244.35,447.48,244.51,446.32,244.74,444.71Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M181.62,354c-2.61-21.44,1.58-41.25,12.44-58.88,10.47-17,25.07-29,44.64-36.63a22,22,0,0,0,7.3-4.83,16.6,16.6,0,0,0,1.3-1.37A6.48,6.48,0,0,0,248,251a6.09,6.09,0,0,0,.36-1.5A10.78,10.78,0,0,0,244,240a10.44,10.44,0,0,0-8.09-2.06,16.6,16.6,0,0,0-3,.68,98.61,98.61,0,0,0-37.67,22.55C168,287,156.52,319.78,161.1,358.66a259.1,259.1,0,0,1-.91,69,268.81,268.81,0,0,1-36.55,100.71c-5.85,9.57-2.32,14.66,1.67,17.24a9.94,9.94,0,0,0,6.67,1.89c3.37-.43,6.22-2.94,9.24-8.16,5.24-9.05,11.17-19.31,16-29.81A284.87,284.87,0,0,0,181.62,354Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M216.47,388.29v-.22a11.73,11.73,0,0,0-4.25-8,9.4,9.4,0,0,0-7-2.12,8.91,8.91,0,0,0-4.91,2.34c-2.82,2.6-4.16,7.27-4.25,10.74-1.31,53.58-15,103.23-40.68,147.56-1.15,2-2.35,4-3.54,5.92-1.93,3.17-3.92,6.45-5.72,9.81-3.05,5.68-2,10.84,2.89,14.14a9.71,9.71,0,0,0,6.73,1.84c2.53-.31,4.94-1.75,7.38-4.4a20.19,20.19,0,0,0,2.55-3.67q.35-.6.72-1.2a325.39,325.39,0,0,0,40.8-95.69C213.73,438.62,216.78,413.42,216.47,388.29Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M284.45,516.74a11.05,11.05,0,0,0-5.32-.75c-3.61.45-6.51,3-8.62,7.55-6.88,14.85-14,29.92-20.82,44.49q-6,12.72-12,25.46A38,38,0,0,0,236,598.1a12.88,12.88,0,0,0-.58,2.49,3.65,3.65,0,0,0,.08,1.88,3.51,3.51,0,0,0,.6,1.29c.47.56.89,1.17,1.51,1.83,1.71,1.82,3.64,3.88,6.22,3.9a10.11,10.11,0,0,0,1.53-.09c3.05-.38,7.2-2.09,9.07-4.93C268,584,279.26,560.59,290,530.76,292.38,524.18,290.3,518.93,284.45,516.74Z"
                    style={{ "fill": "#fff" }}
                />
                <path
                    d="M280,349.15c-.35-3.1-.71-6.29-1.27-9.44-1.06-5.87-5.34-9.06-11.19-8.34-5.61.71-8.94,4.69-8.89,10.66a36.73,36.73,0,0,0,.33,4c.07.53.13,1.05.18,1.58,8.44,84.62-9.66,164-53.78,235.9-2,3.19-6.54,10.66,1.61,16.48a9.37,9.37,0,0,0,6.73,1.82c3.49-.43,6.8-2.94,9.31-7,45.24-74,64.57-155.23,57.44-241.6C280.29,351.84,280.14,350.5,280,349.15Z"
                    style={{ "fill": "#fff" }}
                />
            </svg>
        </Center>
    );
}
