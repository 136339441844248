const jobCategoriesArray = [
    "3-D Printing Specialists",
    "Accountants",
    "Acquaintance",
    "Acquisitions Librarians",
    "Actors",
    "Actuaries",
    "Acupuncturists",
    "Adaptive Physical Education Specialists",
    "Adult and Vocational Education Teachers",
    "Adult Day Care Coordinators",
    "Advanced Practice Nurses",
    "Adventure Travel Specialists",
    "Advertising Account Executives",
    "Advertising Managers",
    "Advertising Workers",
    "Aerobics Instructors and Fitness Trainers",
    "Aeronautical and Aerospace Technicians",
    "Aerospace Engineers",
    "Agribusiness Technicians",
    "Agricultural Consultants",
    "Agricultural Equipment Technicians",
    "Agricultural Pilots",
    "Agricultural Scientists",
    "Air Quality Engineers",
    "Air Traffic Controllers",
    "Aircraft Mechanics",
    "Airplane Dispatchers",
    "Airport Security Personnel",
    "Airport Service Workers",
    "Alcohol and Drug Abuse Counselors",
    "Allergists/Immunologists",
    "Ambassadors",
    "Amusement Park Workers",
    "Anesthesiologist Assistants",
    "Anesthesiologists",
    "Animal Behaviorists",
    "Animal Breeders and Technicians",
    "Animal Caretakers",
    "Animal Handlers",
    "Animal Trainers",
    "Animators",
    "Anthropologists",
    "Antiques and Art Dealers",
    "Apparel Industry Workers",
    "Appliance Service Technicians",
    "Aquaculturists",
    "Aquarists",
    "Arborists",
    "Archaeologists",
    "Archakas",
    "Architects",
    "Archivists",
    "Armored Truck Drivers",
    "Aromatherapists",
    "Art Directors",
    "Art Teachers",
    "Artificial Intelligence Specialists",
    "Artist and Repertoire Workers",
    "Artists",
    "Asbestos Abatement Technicians",
    "Assessors and Appraisers",
    "Astrobiologists",
    "Astrogeologists",
    "Astronauts",
    "Astronomers",
    "Astrophysicists",
    "Athletic Directors",
    "Auctioneers",
    "Audio Recording Engineers",
    "Audit and Assurance Accountants",
    "Auditors",
    "Augmented Reality Developers",
    "Automatic Teller Machine Servicers",
    "Automobile Collision Repairers",
    "Automobile Sales Workers",
    "Automobile Service Technicians",
    "Automotive Dealership Owners",
    "Automotive Dealership Sales Managers",
    "Automotive Designers",
    "Automotive Engineering Technicians",
    "Automotive Engineers",
    "Automotive Industry Workers",
    "Automotive Technology Teachers",
    "Aviation Safety Inspectors",
    "Avionics Engineers",
    "Avionics Technicians",
    "Ayurvedic Doctors and Practitioners",
    "Baggage Porters and Bellhops",
    "Bail Bondsmen",
    "Bailiffs",
    "Bakery Workers",
    "Bank Branch Managers",
    "Bank Examiners",
    "Bankruptcy Lawyers",
    "Barbers",
    "Baristas",
    "Bartenders",
    "Bed and Breakfast Owners",
    "Beekeepers",
    "Beverage Industry Workers",
    "Bicycle Mechanics",
    "Big Data Developers",
    "Billing Clerks",
    "Bindery Workers",
    "Biochemical Engineers",
    "Biochemists",
    "Bioenergy/Biofuels Workers",
    "Biofeedback Therapists",
    "Biofuels Processing Technicians",
    "Biofuels Production Managers",
    "Biofuels/Biodiesel Technology and Product Development Managers",
    "Bioinformatics Specialists",
    "Biologists",
    "Biomass Plant Technicians",
    "Biomass Power Plant Managers",
    "Biomedical Engineers",
    "Biomedical Equipment Technicians",
    "Biometrics Systems Specialists",
    "Biophysicists",
    "Biotechnology Patent Lawyers",
    "Biotechnology Production Workers",
    "Biotechnology Research Assistants",
    "Blockchain Developers",
    "Bloggers",
    "Bodyguards",
    "Boilermakers and Mechanics",
    "Book Conservators",
    "Book Editors",
    "Bookkeeping and Accounting Clerks",
    "Border Patrol Agents",
    "Botanists",
    "Bounty Hunters",
    "Brewers",
    "Bricklayers and Stonemasons",
    "Broadcast Engineers",
    "Brownfield Redevelopment Specialists and Site Managers",
    "Business Continuity Planners",
    "Business Development Managers and Directors",
    "Business Intelligence Analysts",
    "Business Managers",
    "Buyers",
    "Cable Television Technicians",
    "Cage Cashiers",
    "Camera Operators",
    "Campaign Workers",
    "Cancer Registrars",
    "Canning and Preserving Industry Workers",
    "Cantors",
    "Cardiologists",
    "Cardiovascular Technologists",
    "Career and Employment Counselors",
    "Career and Employment Technicians",
    "Carpenters",
    "Cartographers",
    "Cartoonists",
    "Cashiers",
    "Casino Credit Managers",
    "Casino Managers",
    "Caterers",
    "Cement Masons",
    "Ceramics Engineers",
    "Chemical Engineers",
    "Chemical Technicians",
    "Chemists",
    "Chief Customer Officers",
    "Chief Executive Officers",
    "Chief Financial Officers",
    "Chief Information Officers",
    "Chief Sustainability Officers",
    "Child Care Service Owners",
    "Child Care Workers",
    "Child Life Specialists",
    "Children's Librarians",
    "Chimney Sweeps",
    "Chiropractors",
    "Choreographers",
    "Cinematographers and Directors of Photography",
    "Circus Performers",
    "City Managers",
    "Civil Engineering Technicians",
    "Civil Engineers",
    "Civil Litigation Lawyers",
    "Cleaning Service Owners",
    "Client Services Managers",
    "Clinical Applications Specialists",
    "Clinical Data Managers",
    "Clinical Nurse Specialists",
    "Clinical Research Coordinators",
    "Clowns",
    "Coal Miners",
    "Collection Workers",
    "College Administrators",
    "College Professors",
    "Color Analysts",
    "Columnists",
    "Comedians",
    "Comic Book Artists",
    "Comic Book Publishers",
    "Comic Book Writers",
    "Commodities Brokers",
    "Community Health Nurses",
    "Compliance Managers",
    "Composers and Arrangers",
    "Computer and Office Machine Service Technicians",
    "Computer and Video Game Designers",
    "Computer Network Administrators",
    "Computer Programmers",
    "Computer Support Service Owners",
    "Computer Support Specialists",
    "Computer Systems Programmer/Analysts",
    "Computer Trainers",
    "Computer-Aided Design Drafters and Technicians",
    "Confectionery Industry Workers",
    "Congressional Aides",
    "Conservators and Conservation Technicians",
    "Construction Inspectors",
    "Construction Laborers",
    "Construction Managers",
    "Contact Tracers",
    "Continuous Improvement Managers",
    "Cooking Instructors",
    "Cooks and Chefs",
    "Copy Editors",
    "Copywriters",
    "Coremakers",
    "Corporate Climate Strategists",
    "Corporate Community Relations Directors",
    "Corporate Lawyers",
    "Corporate Librarians",
    "Corrections Officers",
    "Cosmetic Surgeons",
    "Cosmeticians",
    "Cosmetics Sales Representatives",
    "Cosmetics Shop Owners and Managers",
    "Cosmetologists",
    "Cost Estimators",
    "Costume Designers",
    "Counter and Retail Clerks",
    "Court Interpreters and Translators",
    "Court Reporters",
    "Cowboys",
    "Craftsman",
    "Creative Arts Therapists",
    "Credit Analysts",
    "Crime Analysts",
    "Criminal Lawyers",
    "Critical Care Nurses",
    "Cruise Ship Workers",
    "Cryptographic Technicians",
    "Cultural Advisers",
    "Customer Service Directors",
    "Customer Service Representatives",
    "Customer Success Managers",
    "Customs Brokers",
    "Customs Officials",
    "Cytogenetic Technologists",
    "Cytotechnologists",
    "Dairy Products Manufacturing Workers",
    "Dance Instructors",
    "Dance School Owners and Managers",
    "Dancers",
    "Data Entry Clerks",
    "Data Processing Technicians",
    "Data Scientists",
    "Data Warehousing Specialists",
    "Database Specialists",
    "Dealers",
    "Demographers",
    "Dental Assistants",
    "Dental Hygienists",
    "Dental Laboratory Technicians",
    "Dentists",
    "Deputy U.S. Marshals",
    "Dermatologists",
    "Desktop Publishing Specialists",
    "Detectives",
    "Diagnostic Medical Sonographers",
    "Dialysis Technicians",
    "Diesel Mechanics",
    "Diet and Fitness Writers",
    "Dietetic Technicians",
    "Dietitians",
    "Digital Advertising Workers",
    "Digital Agents",
    "Digital Designers",
    "Digital Marketing Workers",
    "Directors of Casino Security",
    "Directors of Corporate Sponsorship",
    "Directors of Fund-Raising",
    "Directors of Security",
    "Directors of Volunteers",
    "Disc Jockeys",
    "Dispensing Opticians",
    "Distance Learning Coordinators",
    "Divers and Diving Technicians",
    "Document Management Specialists",
    "Doctors",
    "Doll Designers and Makers",
    "Drafters",
    "Driving School Owners and Instructors",
    "Drone Engineers",
    "Drone Manufacturing Workers",
    "Drone Pilots",
    "Drone Repair Technicians",
    "Drug Developers",
    "Dry Cleaning and Laundry Workers",
    "Drywall Installers and Finishers",
    "Ear, Nose, and Throat Specialists",
    "Ecologists",
    "Economists",
    "Editorial Assistants",
    "Editorial Research Assistants",
    "Editors",
    "Education Directors and Museum Teachers",
    "Elder Law Attorneys",
    "Electrical Engineering Technologists",
    "Electrical Engineers",
    "Electricians",
    "Electrologists",
    "Electromechanical Engineering Technologists",
    "Electroneurodiagnostic Technologists",
    "Electronics Engineering Technicians",
    "Electronics Engineers",
    "Electronics Service Technicians",
    "Electroplating Workers",
    "Elementary School Teachers",
    "Elevator Installers and Repairers",
    "Embedded Systems Engineers",
    "Emergency Medical Technicians",
    "Emergency Nurses",
    "Emergency Services Dispatchers",
    "Employment Firm Workers",
    "Endocrinologists",
    "Endodontists",
    "Endoscopy Technicians",
    "Energy Brokers",
    "Energy Conservation Technicians",
    "Energy Consultants",
    "Energy Efficiency Engineers",
    "Energy Transmission and Distribution Workers",
    "Engineering Technicians",
    "Engineers",
    "English as a Second Language (ESL) Teachers",
    "Enologists",
    "Environmental Consultants",
    "Environmental Economists",
    "Environmental Education Program Directors",
    "Environmental Engineers",
    "Environmental Lawyers",
    "Environmental Lobbyists",
    "Environmental Planners",
    "Environmental Restoration Planners",
    "Environmental Scientists",
    "Environmental Technicians",
    "EPA Special Agents",
    "Epidemiologists",
    "Ergonomists",
    "Ethnoscientists",
    "Event Planners",
    "Executive Recruiters",
    "Exercise Physiologists",
    "Exhibit Designers",
    "Export-Import Specialists",
    "Fabric Designers",
    "Family and Consumer Scientists",
    "Family Lawyers",
    "Farm Crop Production Technicians",
    "Farm Equipment Mechanics",
    "Farmers",
    "Farmers' Market Managers/Promoters",
    "Fashion Coordinators",
    "Fashion Designers",
    "Fashion Illustrators",
    "Fashion Models' Agents",
    "Fashion Photographers",
    "Fashion Stylists",
    "Fashion Writers and Editors",
    "Fast Food Workers",
    "FBI Agents",
    "Federal and State Officials",
    "Fiber Optics Technicians",
    "Film and Television Directors",
    "Film and Television Editors",
    "Film and Television Extras",
    "Film and Television Producers",
    "Film and Video Librarians",
    "Financial Analysts",
    "Financial Consultants",
    "Financial Institution Officers and Managers",
    "Financial Institution Tellers, Clerks, and Related Workers",
    "Financial Planners",
    "Financial Quantitative Analysts",
    "Financial Services Brokers",
    "Fire Inspectors",
    "Fire Investigators",
    "Fire Protection Engineers",
    "Fire Safety Directors",
    "Fire Safety Technicians",
    "Firefighters",
    "Fish and Game Wardens",
    "Fishers",
    "Fitness Directors",
    "Flight Attendants",
    "Flight Instructors",
    "Floor Covering Installers",
    "Florists",
    "Fluid Power Technicians",
    "Food Photographers",
    "Food Service Workers",
    "Food Technologists",
    "Food Writers and Editors",
    "Foreign Correspondents",
    "Foreign Service Officers",
    "Forensic Accountants and Auditors",
    "Forensic Engineers",
    "Forensic Experts",
    "Forest Fire Prevention Specialists",
    "Foresters",
    "Forestry Technicians",
    "Forge Shop Workers",
    "Franchise Owners",
    "Fraud Examiners, Investigators, and Analysts",
    "Friend",
    "Fuel Cell Engineers",
    "Fuel Cell Technicians",
    "Fuel Cell Technology Workers",
    "Full Stack Developers/Engineers",
    "Fund-Raisers",
    "Funeral Home Workers",
    "Furniture Designers",
    "Furniture Manufacturing Workers",
    "Futurists",
    "Gallery Owners and Directors",
    "Gaming Occupations",
    "Gardener",
    "Gastroenterologists",
    "Genealogists",
    "General Maintenance Mechanics",
    "General Practitioners",
    "Genetic Counselors",
    "Genetic Engineers",
    "Genetic Scientists",
    "Geodetic Surveyors",
    "Geographers",
    "Geographic Information Systems Specialists",
    "Geological Technicians",
    "Geologists",
    "Geophysicists",
    "Geotechnical Engineers",
    "Geothermal Energy Industry Workers",
    "Geothermal Production Managers",
    "Geothermal Technicians",
    "Geriatric Care Managers",
    "Geriatric Nurses",
    "Geriatric Psychiatrists",
    "Geriatric Social Workers",
    "Geriatricians",
    "Glass Manufacturing Workers",
    "Glaziers",
    "Grain Merchants",
    "Grant Coordinators and Writers",
    "Graphic Designers",
    "Graphics Programmers",
    "Green Builders",
    "Green Hotel/Resort Ecomanagers",
    "Green Marketers",
    "Green Products Manufacturers",
    "Green Transportation Careers",
    "Greeting Card Designers and Writers",
    "Grief Therapists",
    "Ground Services Workers",
    "Grounds Managers",
    "Groundwater Professionals",
    "Guidance Counselors",
    "Gunsmiths",
    "Hardware Engineers",
    "Hazardous Waste Management Specialists",
    "Hazardous Waste Management Technicians",
    "Health Advocates",
    "Health and Regulatory Inspectors",
    "Health Care Consultants",
    "Health Care Insurance Navigators",
    "Health Care Managers",
    "Health Club Owners and Managers",
    "Heat Treaters",
    "Heating and Cooling Technicians",
    "Hedge Fund Administrators",
    "Hedge Fund Analysts",
    "Hedge Fund Compliance Professionals",
    "Hedge Fund Investor Relations Specialists",
    "Hedge Fund Lawyers",
    "Hedge Fund Managers",
    "Hedge Fund Relationship Managers",
    "Hedge Fund Risk Managers",
    "Help Desk Representatives",
    "Hematologists",
    "Herbalists",
    "Hire a Hubby",
    "Histologic Technicians",
    "Historians",
    "Historic Preservationists",
    "HIV/AIDS Counselors and Case Managers",
    "Holistic Physicians",
    "Home Health Care Aides",
    "Home Health Care and Hospice Nurses",
    "Home Stagers",
    "Homeopaths",
    "Horticultural Inspectors",
    "Horticultural Technicians",
    "Horticultural Therapists",
    "Hospice Workers",
    "Hospitalists",
    "Hosts/Hostesses",
    "Hotel and Motel Managers",
    "Hotel Concierges",
    "Hotel Desk Clerks",
    "Hotel Executive Housekeepers",
    "Hotel Restaurant Managers",
    "Household Movers",
    "Household Workers",
    "Housekeepers and Maids",
    "Human Resources Consultants",
    "Human Resources Managers",
    "Human Services Workers",
    "Hydroelectric Plant Technicians",
    "Hydroelectric Production Managers",
    "Hydropower and Marine Energy Industry Workers",
    "Hypnotherapists",
    "Illustrators",
    "Image Consultants",
    "Imams",
    "Inbound Tour Guides",
    "Indexers",
    "Industrial Chemicals Workers",
    "Industrial Designers",
    "Industrial Ecologists",
    "Industrial Engineering Technicians",
    "Industrial Engineers",
    "Industrial Machinery Mechanics",
    "Industrial Radiographers",
    "Industrial Safety and Health Technicians",
    "Industrial Traffic Managers",
    "Industrial-Organizational Psychologists",
    "Informatics Nurse Specialists",
    "Information Assurance Analysts",
    "Information Brokers",
    "Information Security Analysts",
    "Information Technology Consultants",
    "Information Technology Project Managers",
    "Information Technology Security Consultants",
    "Instructional Coordinators",
    "Instructional Designers",
    "Instrumentation Technicians",
    "Insulators/Insulation Workers",
    "Insurance Claims Representatives",
    "Insurance Fraud Investigators",
    "Insurance Policy Processing Workers",
    "Insurance Underwriters",
    "Intellectual Property Lawyers",
    "Intelligence Officers",
    "Interior Designers and Decorators",
    "Internet Consultants",
    "Internet Content Curators",
    "Internet Developers",
    "Internet Executives",
    "Internet Marketing and Advertising Consultants",
    "Internet Quality Assurance Specialists",
    "Internet Security Specialists",
    "Internet Store Managers and Entrepreneurs",
    "Internet Transaction Specialists",
    "Interpreters",
    "Investment Bankers",
    "Investment Banking Analysts",
    "Investment Banking Associates",
    "Investment Banking Sales Brokers",
    "Investment Banking Traders",
    "Investment Fund Managers",
    "Investment Professionals",
    "Investment Underwriters",
    "Janitors and Cleaners",
    "Jewelers and Jewelry Repairers",
    "Job and Die Setters",
    "Jockeys",
    "Journalism Teachers",
    "Judges",
    "Kinesiologists",
    "Labor Union Business Agents",
    "Laboratory Technicians and Technologists",
    "Laboratory Testing Technicians",
    "Land Acquisition Professionals",
    "Land Trust or Preserve Managers",
    "Landmen",
    "Landscape Architects",
    "Landscapers",
    "Laser Technicians",
    "Lathers",
    "Law Librarians",
    "Lawn and Gardening Service Owners",
    "Lawyers",
    "Layout Workers",
    "Leather Tanning and Finishing Workers",
    "Legal Nurse Consultants",
    "Legal Secretaries",
    "Lexicographers",
    "Librarians",
    "Library and Information Science Instructors",
    "Library Assistants",
    "Library Directors",
    "Library Media Specialists",
    "Library Technicians",
    "Licensed Practical Nurses",
    "Life Insurance Agents and Brokers",
    "Lifeguards and Swimming Instructors",
    "Lighting Technicians",
    "Line Installers and Cable Splicers",
    "Linguists",
    "Literary Agents",
    "Litigation Support/eDiscovery Analysts",
    "Loan Officers and Counselors",
    "Loan Processors",
    "Loan Underwriters",
    "Lobbyists",
    "Locksmiths",
    "Locomotive Engineers",
    "Logging Industry Workers",
    "Logistics Analysts",
    "Logistics Engineers",
    "Loss Prevention Managers",
    "Machine Learning Engineers",
    "Magazine Editors",
    "Magicians",
    "Mail Carriers",
    "Makeup Artists",
    "Management Analysts and Consultants",
    "Manufacturing Engineering Technologists",
    "Manufacturing Engineers",
    "Manufacturing Production Technicians",
    "Manufacturing Supervisors",
    "Marble Setters, Tile Setters, and Terrazzo Workers",
    "Marine Biologists",
    "Marine Engineers",
    "Marine Services Technicians",
    "Market Research Analysts",
    "Marketing Consultants",
    "Marketing Managers",
    "Massage Therapists",
    "Materials Engineers",
    "Mathematicians",
    "Mathematics Teachers",
    "Meatcutters and Meat Packers",
    "Mechanical Engineering Technicians",
    "Mechanical Engineers",
    "Mechatronics Engineers",
    "Media Planners and Buyers",
    "Media Relations Specialists",
    "Medical Assistants",
    "Medical Billing Service Owners",
    "Medical Ethicists",
    "Medical Illustrators and Photographers",
    "Medical Laboratory Technicians",
    "Medical Librarians",
    "Medical Record Technicians",
    "Medical Scientists",
    "Medical Scribes",
    "Medical Secretaries",
    "Medical Technologists",
    "Medical Transcriptionists",
    "Merchandise Displayers",
    "Merchant Mariners",
    "Mergers and Acquisitions Attorneys",
    "Metallurgical Engineers",
    "Metallurgical Technicians",
    "Meteorologists",
    "Meter Readers, Utilities",
    "Methane/Landfill Gas Collection System Operators",
    "Methane/Landfill Gas Generation System Technicians",
    "Microbiologists",
    "Microelectronics Technicians",
    "Microfabrication Engineers",
    "Microfabrication Technicians",
    "Military Pilots",
    "Military Police",
    "Military Recruiters",
    "Military Workers, Enlisted",
    "Military Workers, Officers",
    "Millwrights",
    "Mining Engineers",
    "Mobile Software Developers",
    "Models",
    "Molders",
    "Molecular and Cellular Biologists",
    "Mortgage Bankers",
    "Mortuary Cosmetologists",
    "Motivational Speakers",
    "Multimedia Artists and Animators",
    "Multimedia Sound Workers",
    "Museum Attendants",
    "Museum Directors and Curators",
    "Museum Technicians",
    "Music Agents and Scouts",
    "Music Conductors and Directors",
    "Music Journalists",
    "Music Librarians",
    "Music Producers",
    "Music Teachers",
    "Music Therapists",
    "Music Venue Owners and Managers",
    "Music Video Directors and Producers",
    "Music Video Editors",
    "Musical Instrument Repairers and Tuners",
    "Musicians",
    "Mutual Fund Accountants and Auditors",
    "Mutual Fund Analysts",
    "Mutual Fund Compliance Professionals",
    "Mutual Fund Customer Service Representatives",
    "Mutual Fund Financial Managers",
    "Mutual Fund Lawyers",
    "Mutual Fund Marketing Specialists",
    "Mutual Fund Portfolio Managers",
    "Mutual Fund Risk Managers",
    "Mutual Fund Wholesalers",
    "Myotherapists",
    "Nail Technicians",
    "Nannies",
    "Nanomaterials Scientists",
    "Nanosystems Engineers",
    "Nanotechnicians",
    "Nanotechnologists",
    "National Park Service Employees",
    "Naturalists",
    "Naturopaths",
    "Neonatal Nurses",
    "Neurologists",
    "Neuropsychologists and Clinical Neuropsychologists",
    "News Anchors",
    "Newspaper Editors",
    "Non-Destructive Testing Specialists",
    "Nonprofit Social Service Directors",
    "Nuclear Engineers",
    "Nuclear Medicine Physicians",
    "Nuclear Medicine Technologists",
    "Nuclear Reactor Operators and Technicians",
    "Numerical Control Tool Programmers",
    "Nurse Anesthetists",
    "Nurse Assistants",
    "Nurse Managers",
    "Nurse Practitioners",
    "Nurse-Midwives",
    "Nursery Owners and Managers",
    "Nursing Home Administrators",
    "Nursing Instructors",
    "Nutritionists",
    "Obstetricians/Gynecologists",
    "Occupational Health Nurses",
    "Occupational Safety and Health Workers",
    "Occupational Therapists",
    "Occupational Therapy Assistants and Aides",
    "Oceanographers",
    "Office Administrators",
    "Oncological Nurses",
    "Oncologists",
    "Online Journalists",
    "Online Producers",
    "Online Reputation Managers",
    "Operating Engineers",
    "Ophthalmic Laboratory Technicians",
    "Ophthalmic Medical Technologists",
    "Ophthalmologists",
    "Optical Engineers",
    "Optics Technicians",
    "Optometrists",
    "Oral Surgeons",
    "Organic Farmers",
    "Oriental Medicine Practitioners",
    "Orientation and Mobility Specialists",
    "Orthodontists",
    "Orthoptists",
    "Orthotic and Prosthetic Technicians",
    "Orthotists and Prosthetists",
    "Osteopathic Physicians",
    "Other",
    "Packaging Engineers",
    "Packaging Machinery Technicians",
    "Painters and Paperhangers",
    "Paleontologists",
    "Paper Processing Workers",
    "Paralegals",
    "Park Rangers",
    "Parking Attendants",
    "Parole Officers",
    "Patent Agents",
    "Patent Lawyers",
    "Pathologists",
    "Payroll Directors",
    "Pediatric Dentists",
    "Pediatricians",
    "Pedorthists",
    "Perfusionists",
    "Periodontists",
    "Personal Care Aides",
    "Personal Chefs",
    "Personal Privacy Advisors",
    "Personal Shoppers",
    "Personal Trainers",
    "Personnel and Labor Relations Specialists",
    "Pest Control Workers",
    "Pet Shop Workers",
    "Pet Sitters",
    "Petroleum Engineers",
    "Petroleum Technicians",
    "Pharmaceutical Industry Workers",
    "Pharmacists",
    "Pharmacologists",
    "Pharmacy Technicians",
    "Phlebotomy Technicians",
    "Photo Editors",
    "Photographers",
    "Photographic Equipment Technicians",
    "Photographic Laboratory Workers",
    "Photography Instructors",
    "Photojournalists",
    "Physiatrists",
    "Physical Education Teachers",
    "Physical Therapists",
    "Physical Therapy Assistants",
    "Physician Assistants",
    "Physicians",
    "Physicists",
    "Pilots",
    "Pit Bosses",
    "Plasterers",
    "Plastics Engineers",
    "Plastics Products Manufacturing Workers",
    "Plastics Technicians",
    "Playwrights",
    "Plumbers and Pipefitters",
    "Podcasters",
    "Podiatrists",
    "Police Officers",
    "Policy Analysts",
    "Political Columnists and Writers",
    "Political Consultants",
    "Political Reporters",
    "Political Scientists",
    "Political Speechwriters",
    "Polygraph Examiners",
    "Pop/Rock Musicians",
    "Postal Clerks",
    "Power Plant Workers",
    "Precision Machinists",
    "Precision Metalworkers",
    "Prepress Workers",
    "Preschool Teachers",
    "Press Secretaries",
    "Preventive Medicine Physicians",
    "Printing Press Operators and Assistants",
    "Private Bankers",
    "Private Equity Accountants and Auditors",
    "Private Equity Business Development Directors",
    "Private Equity Chief Dealmakers",
    "Private Equity Compliance Professionals",
    "Private Equity Financial Managers",
    "Private Equity Investor Relations Specialists",
    "Private Equity Lawyers",
    "Private Equity Marketing Specialists",
    "Private Equity Research Analysts and Associates",
    "Private Equity Risk Managers",
    "Private Investigators",
    "Process Servers",
    "Product Analysts",
    "Product Development Directors",
    "Product Management Directors",
    "Product Managers",
    "Production Assistants",
    "Production Designers and Art Directors",
    "Professional Athletes, Individual Sports",
    "Professional Athletes, Team Sports",
    "Professional Hackers",
    "Professional Organizers",
    "Project Managers",
    "Property and Casualty Insurance Agents and Brokers",
    "Property and Real Estate Managers",
    "Proposal Managers",
    "Prosthodontists",
    "Protestant Ministers",
    "Psychiatric Nurses",
    "Psychiatric Technicians",
    "Psychiatrists",
    "Psychologists",
    "Public Interest Lawyers",
    "Public Opinion Researchers",
    "Public Relations Managers",
    "Public Relations Specialists",
    "Public Transportation Operators",
    "Publicists",
    "Publicity Photographers",
    "Purchasing Agents",
    "Purohits",
    "Quality Control Engineers",
    "Quality Control Technicians",
    "Quantity Surveyors",
    "Rabbis",
    "Radiation Protection Technicians",
    "Radio and Television Announcers",
    "Radio and Television Program Directors",
    "Radio Frequency Identification Device Specialists",
    "Radio Producers",
    "Radiologic Technologists",
    "Radiologists",
    "Railroad Conductors",
    "Range Managers",
    "Real Estate Agents and Brokers",
    "Real Estate Clerks",
    "Real Estate Developers",
    "Real Estate Educators",
    "Real Estate Lawyers",
    "Real Estate Writers",
    "Real-Time Captioners",
    "Receptionists",
    "Recreation Workers",
    "Recreational Therapists",
    "Recycling and Reclamation Workers",
    "Recycling Coordinators",
    "Reflexologists",
    "Refuse Collectors",
    "Regional and Local Officials",
    "Registered Nurses",
    "Regulatory Affairs Managers",
    "Regulatory Affairs Specialists",
    "Rehabilitation Counselors",
    "Remote Health Care Engineers",
    "Remote Sensing Scientists and Technologists",
    "Remote Sensing Technicians",
    "Renewable Energy Careers",
    "Renewable Energy Engineers",
    "Reporters",
    "Reservation and Ticket Agents",
    "Resort Workers",
    "Respiratory Technicians",
    "Respiratory Therapists",
    "Restaurant and Food Service Managers",
    "Retail Business Owners",
    "Retail Loss Prevention Specialists",
    "Retail Managers",
    "Retail Sales Workers",
    "Retirement Planners",
    "Risk Managers",
    "Robotics Engineers",
    "Robotics Technicians",
    "Roofers",
    "Roustabouts",
    "Rubber Goods Production Workers",
    "Sales Development Representatives",
    "Sales Engineers",
    "Sales Managers",
    "Sales Representatives",
    "School Administrators",
    "School Nurses",
    "Science and Medical Writers",
    "Screenwriters",
    "Search Engine Optimization Specialists",
    "Secondary School Teachers",
    "Secret Service Special Agents",
    "Secretaries",
    "Security Consultants",
    "Security Guards",
    "Security Systems Installers and Workers",
    "Semiconductor Technicians",
    "Senior Care Pharmacists",
    "Sheet Metal Workers",
    "Ship's Captains",
    "Sign Language and Oral Interpreters",
    "Signal Mechanics",
    "Silverware Artisans and Workers",
    "Singers",
    "Site Reliability Engineers",
    "Ski Resort Workers",
    "Smart Building Systems Designers",
    "Social Media Influencers",
    "Social Media Workers",
    "Social Workers",
    "Sociologists",
    "Software Application Developers",
    "Software Designers",
    "Software Engineers",
    "Software Quality Assurance Testers",
    "Soil Conservationists and Technicians",
    "Soil Scientists",
    "Solar Energy Industry Workers",
    "Solar Energy Installation Managers",
    "Solar Energy Sales Representatives",
    "Solar Engineers",
    "Solar Thermal Installers and Technicians",
    "Solutions Architects",
    "Songwriters",
    "Spa Attendants",
    "Spa Managers",
    "Special and Visual Effects Technicians",
    "Special Education Teachers",
    "Special Procedures Technologists",
    "Speech-Language Pathologists and Audiologists",
    "Speech-Language Pathology Assistants",
    "Sporting Goods Production Workers",
    "Sports Agents",
    "Sports Broadcasters and Announcers",
    "Sports Equipment Managers",
    "Sports Executives",
    "Sports Facility Managers",
    "Sports Instructors and Coaches",
    "Sports Photographers",
    "Sports Physicians",
    "Sports Psychologists",
    "Sports Publicists",
    "Sports Scouts",
    "Sports Trainers",
    "Sportswriters",
    "Stadium Ushers and Vendors",
    "Stage Directors",
    "Stage Managers",
    "Stage Production Workers",
    "Stationary Engineers",
    "Statisticians",
    "Steel Industry Workers",
    "Stenographers",
    "Stevedores",
    "Stock Clerks",
    "Strategy Managers",
    "Strength and Conditioning Coaches",
    "Stunt Performers",
    "Supermarket Workers",
    "Supply Chain Managers",
    "Surgeons",
    "Surgical Technologists",
    "Surveying and Mapping Technicians",
    "Surveyors",
    "Swimming Pool Servicers",
    "Systems Setup Specialists",
    "Tailors and Dressmakers",
    "Talent Agents and Scouts",
    "Tax Accountants",
    "Tax Attorneys",
    "Tax Preparers",
    "Taxi Drivers",
    "Taxidermists",
    "Teacher Aides",
    "Technical Support Specialists",
    "Technical Writers and Editors",
    "Telecommunications Network Engineers",
    "Telemarketers",
    "Telephone and PBX Installers and Repairers",
    "Telephone Operators",
    "Temporary Workers",
    "Test Drivers",
    "Textile Manufacturing Workers",
    "Theater Managers",
    "Tire Technicians",
    "Title Searchers and Examiners",
    "Tobacco Products Industry Workers",
    "Toll Collectors",
    "Tour Guides",
    "Toxicologists",
    "Toy and Game Designers",
    "Toy Industry Workers",
    "Tradesman",
    "Traffic Engineers",
    "Traffic Managers",
    "Translators",
    "Transplant Coordinators",
    "Transportation Engineers",
    "Transportation Planners",
    "Travel Agents",
    "Truck Dispatchers",
    "Truck Drivers",
    "Tutors and Trainers",
    "Typists and Word Processors",
    "Umpires and Referees",
    "Unity Developers",
    "Urban and Regional Planners",
    "Urologists",
    "User Experience Designers",
    "Venture Capital Accountants and Auditors",
    "Venture Capital Analysts",
    "Venture Capital Associates",
    "Venture Capital Chief Financial Officers",
    "Venture Capital Investor Relations Specialists",
    "Venture Capital Lawyers",
    "Venture Capital Marketing Specialists",
    "Venture Capital Principals",
    "Venture Capital Risk Managers",
    "Venture Capitalists",
    "Veterinarians",
    "Veterinary Technicians",
    "Video Game Art Directors",
    "Video Game Producers",
    "Video Game Testers",
    "Wastewater Treatment Plant Operators and Technicians",
    "Watch and Clock Repairers",
    "Water/Wastewater Engineers",
    "Wealth Management Accountants",
    "Wealth Management Analysts",
    "Wealth Management Associates",
    "Wealth Management Compliance Professionals",
    "Wealth Management Investor Relations Specialists",
    "Wealth Management Lawyers",
    "Wealth Management Managing Directors",
    "Wealth Management Risk Managers",
    "Wealth Management Vice Presidents",
    "Webmasters",
    "Wedding and Party Consultants",
    "Welders and Welding Technicians",
    "Wind Energy Engineers",
    "Wind Energy Industry Workers",
    "Wind Energy Operations Managers",
    "Wind Energy Project Managers",
    "Wireless Service Technicians",
    "Wood Science and Technology Workers",
    "Writers",
    "Yoga and Pilates Instructors",
    "Zoo and Aquarium Curators and Directors",
    "Zookeepers",
    "Zoologists"
]

export default jobCategoriesArray